import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useForm } from "react-hook-form"
import "twin.macro"
import Button from "../components/button"
import { navigate } from "gatsby"
import AccountLayout from "../components/accountLayout"
import SEO from '../components/seo'

const emailValidator = require('email-validator')

const Forgot = () => {

	const { register, errors, handleSubmit } = useForm();
	const [ forgotErr, setForgotErr] = useState()

	const onSubmit = (data) => {
		Auth.forgotPassword(data.email)
		.then( response => {
			navigate('/newpassword', { state: {email:data.email}})
		})
		.catch(err => {
			console.log(err)
			if (err.code === 'UserNotFoundException') {
				err.message = 'Email address not found. Please try again.'
			}
			setForgotErr(err.message)
		})
	}

	return <>
		<SEO title="Reset Password"/>
		<AccountLayout onSubmit={handleSubmit(onSubmit)} message="Reset your file.io password" instructions={<div>
			To reset your password, enter your email address below then click 'Send Code' to receive an email verification code.
		</div>}>
			<label htmlFor="email">Email address</label>
			<input
				name="email"
				type="text"
				placeholder="Email"
				ref={register({
					required:true,
					validate: value => emailValidator.validate(value)
				})}
			/>
			{errors.email && <div className="error">Please enter a valid email address</div>}
			{forgotErr && <div className="error">{forgotErr}</div>}
			<Button tw="w-full" type="submit" special={true}>Send Code</Button>
		</AccountLayout>
	</>
}

export default Forgot